import { getOrderDetail } from 'client/receipt/receipt.client'
import { getCurrentCountry } from 'client/utilities.client-helper'
import type { OrderDetail } from 'types/receipt/model'
import { MEASEURE_UNITS_MAP, MEASURE_UNITS } from 'utils/constants'

export const isAnOrder = (data: unknown): data is OrderDetail => {
  const hasAllProperties =
    !!data &&
    typeof data === 'object' &&
    'receiptId' in data &&
    'orderId' in data &&
    'numberSkus' in data &&
    'provider' in data &&
    'products' in data
  if (!hasAllProperties) {
    return false
  }

  if (!Array.isArray(data.products)) {
    return false
  }

  return data.products.every((product) => {
    return (
      product &&
      typeof product === 'object' &&
      'quantity' in product &&
      'barcode' in product &&
      'description' in product &&
      'status' in product &&
      'imgUrl' in product &&
      'unitsOfMeasurment' in product &&
      'measureUnit' in product &&
      'totalReceived' in product
    )
  })
}

export const orderDetailLoader = async ({ params }) => {
  const country = getCurrentCountry()
  const orderDetail = await getOrderDetail(String(params.receiptId))
  orderDetail.products.forEach((product) => {
    product.measureUnit = MEASEURE_UNITS_MAP[product.measureUnit]
    product.totalUnits = product.quantity * product.unitsOfMeasurment
    if (product.measureUnit === MEASURE_UNITS.kg) {
      product.totalUnits *= 1000 // to convert to grams
      product.totalUnits = +product.totalUnits.toFixed(2)

      if (country === 'BR') {
        product.upperTotalLimit = product.totalUnits
      } else {
        product.upperTotalLimit = product.totalUnits + product.totalUnits * 0.2
        product.upperTotalLimit = +product.upperTotalLimit.toFixed(2)
      }
    } else {
      product.upperTotalLimit = product.totalUnits
    }
  })
  return orderDetail
}
