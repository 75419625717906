{
  "loader": {
    "message": "Aguarde, por favor..."
  },
  "commons": {
    "continue": "Continuar",
    "cancel": "Cancelar",
    "finish": "Finalizar",
    "enter": "Entrar"
  },
  "validations": {
    "temp-range": "A temperatura não está dentro da faixa permitida.",
    "generic-error": "Algo deu errado",
    "required": "Campo obrigatório",
    "invalid-amount": "Insira uma quantidade válida",
    "greater-total-units": "A quantidade é maior que a solicitada, escreva a quantidade correta.",
    "expired-date": "A data inserida está expirada",
    "alphanum": "Insira um valor alfanumérico",
    "single-select": "Selecione uma opção",
    "multi-select": "Selecione pelo menos uma opção",
    "invalid-date": "Formato de data inválido",
    "not-enough-lifetime": "Não cumpre o prazo de validade"
  },
  "receipt": {
    "title": "Recibo do fornecedor",
    "select-order": "Selecione um pedido:",
    "order-id": "Pedido: {{orderId}}",
    "total-sku": "Núm. de SKUs: {{totalSKU}}",
    "type-error": "Esperado um array de pedidos nos dados do carregador",
    "provider": "Fornecedor: {{provider}}",
    "sync": "Sincronizar",
    "no-data-title": "Você não tem pedidos sincronizados",
    "no-data-help": "Por favor, clique no botão \"Sincronizar\""
  },
  "scanner-molecule": {
    "title": "Escaneie",
    "use-scanner": "Use seu scanner"
  },
  "receipt-order-detail": {
    "total-sku": "Total de SKU a receber",
    "boxes-counter": "Quantidade solicitada: {{received}}/{{total}} unidades",
    "expected-grams": "Quantidade solicitada: {{received}}/{{total}} g",
    "end": "Terminar",
    "pending": "Para receber ({{counter}})",
    "finished": "Registrados ({{counter}})",
    "type-error": "Esperava-se uma ordem de compra nos dados do carregador",
    "scan-product": "Escaneie o produto a receber",
    "invalid-upc": "Escanee um UPC válido. Foi escaneado: {{upc}}",
    "nonexistent-upc": "O produto escaneado não está na ordem de compra atual",
    "registered-upc": "O produto escaneado já foi registrado",
    "upc": "UPC: {{upc}}",
    "full-missing": "Deseja reportar este produto como faltante completo?",
    "undo-full-missing": "Deseja que o produto não esteja mais completamente em falta?",
    "missing-error": "Erro ao marcar o produto como falta total",
    "manual-upc": "Insira manualmente o código do produto",
    "upc-textbox": "Código do produto"
  },
  "receipt-scan-order": {
    "title": "Número do pedido",
    "scan-label": "Documento de entrada {{type}}",
    "textbox": "Digite o número do pedido de compra",
    "invalid-input": "Escaneie um número de pedido válido. Foi escaneado: {{orderNumber}}",
    "sync-error": "Não foi possível sincronizar a ordem: {{orderNumber}}",
    "sync-success": "Pedido sincronizado."
  },
  "receipt-scan-container": {
    "title": "Escaneie",
    "scan-label": "Pallet",
    "product": "Produto escaneado",
    "product-undefined": "Para escanear um pallet, primeiro você deve escanear um produto",
    "invalid-input": "Escanee um LPN válido. Foi escaneado: {{lpn}}",
    "invalid-warehouse-lpn": "Escaneie um LPN que pertença ao armazém atual ({{current}}). Escaneado: {{scanned}}",
    "displaced-container": "Este contêiner não está na posição de pré-armazenamento. Por favor, escolha outro contêiner."
  },
  "receipt-product-lots": {
    "measure": "Unidade de medida: {{measure}} unidades por caixa",
    "measure-kg": "Unidade de medida: {{measure}} g por caixa",
    "requested-boxes": "Caixas solicitadas",
    "requested-units": "Unidades solicitadas",
    "requested-quantity": "Quantidade solicitada",
    "add-lot": "Adicionar lote",
    "end": "Finalizar",
    "type-error": "Esperava-se informações de lotes nos dados do carregador",
    "counter": "{{counter}}/{{total}}",
    "counter-kg": "{{counter}} g/{{total}} g",
    "get-order-error": "Ocorreu um erro ao recuperar o detalhe da ordem"
  },
  "receipt-lot-form": {
    "lot-number": "Lote: {{lotNumber}}",
    "lot": "Lote",
    "expiration": "Data de validade (dd/mm/aaaa)",
    "units": "Quantidade total de unidades",
    "units-hint": "Insira a quantidade total pelo número de caixas que você adicionou neste lote.",
    "boxes": "Quantidade de caixas",
    "grams": "Quantidade em gramas",
    "temperature": "Temperatura (em graus)"
  },
  "receipt-missing-dialog": {
    "missing-type": "Selecione o tipo de faltante",
    "full-missing": "Faltante completo",
    "rejection": "Rejeição",
    "title": "Você precisa receber {{missing}} unidades, como irá registrá-las?",
    "title-kg": "Você precisa receber {{missing}} gramas, como irá registrá-los?",
    "title-box": "Você precisa receber {{missing}} caixas, como irá registrá-las?",
    "continue": "Continuar",
    "back": "Voltar",
    "reject": "Rejeição de produto",
    "partial-missing": "Relatório de faltante parcial"
  },
  "receipt-partial-missing": {
    "title": "Registrar a falta",
    "subtitle": "Escreva a quantidade que falta do produto",
    "total-missing": "Quantidade em falta",
    "total-kg": "Quantidade em falta em gramas",
    "missing-boxes": "Caixas faltantes",
    "register": "Registrar",
    "type-error": "Esperava-se informações de totais nos dados do carregador",
    "missing-error": "Erro ao armazenar a falta parcial"
  },
  "receipt-rejection": {
    "title": "Registrar a rejeição",
    "evidence": "Carregue as evidências",
    "max-evidences": "Máximo de 6 fotos.",
    "form-title": "Escreva a quantidade, lote e selecione o motivo",
    "units": "Unidades a rejeitar",
    "units-kg": "Quantidade a rejeitar em gramas",
    "boxes": "Caixas a rejeitar",
    "lot": "Lote",
    "register": "Registrar",
    "upload-error": "Ocorreu um erro ao carregar a imagem, tente novamente",
    "create-error": "Ocorreu um erro ao tentar criar a rejeição",
    "type-error": "Esperava-se a informação de uma rejeição nos dados do carregador",
    "missing-photo": "Você deve tirar pelo menos uma foto da rejeição",
    "reasons": {
      "NEAR_EXPIRATION": "Validade curta",
      "PRODUCT_DAMAGED": "Produto quebrado",
      "WITHOUT_LABELING": "Produto sem rotulagem",
      "MOLD": "Mofo",
      "NO_FUMIGATION_CERT": "Sem certificado de fumigação",
      "NO_APPOINTMENT": "Fornecedor sem agendamento",
      "OUT_OF_HOURS": "Fornecedor fora do horário",
      "HARMFUL_FAUNA": "Fauna nociva",
      "EXTRA_PRODUCT": "Produto extra",
      "BAD_UPC": "UPC não corresponde",
      "PACKAGING_DAMAGE": "Embalagem quebrada",
      "OUT_OF_RANGE_TEMP": "Temperatura fora da faixa",
      "DEHYDRATED": "Desidratação",
      "DECAY": "Apodrecimento",
      "RIPENING": "Amadurecimento",
      "MECHANICAL_DAMAGE": "Dano mecânico",
      "OXIDATION": "Oxidação",
      "SOGGINESS": "Encharcamento",
      "OVER_WEIGHT": "Excesso de peso"
    }
  },
  "receipt-containers": {
    "title": "Pallets",
    "type-error": "Esperava-se um array de pallets nos dados do carregador",
    "toMove": "Por mover ({{counter}})",
    "moved": "Movido ({{counter}})",
    "container": "Pallet: {{container}}",
    "skus": "Núm. de SKUs: {{totalSKU}}",
    "scan-label": "Escaneie o pallet a ser movido.",
    "finish-order": "Finalizar pedido",
    "invalid-container": "O pallet escaneado {{scanned}} não existe na ordem atual",
    "location": "Ubicação escaneada: {{location}}",
    "finish-error": "Ocorreu um erro ao finalizar o recebimento",
    "finish-success": "Recebimento finalizado com sucesso"
  },
  "receipt-summary": {
    "title": "Resumo",
    "type-error": "Esperava-se um array com o resumo do recebimento do produto nos dados do carregador",
    "lot": "Lote: {{lot}}",
    "amount": "Quantidade: {{amount}} unidades",
    "amount-kg": "Quantidade: {{amount}} g",
    "expiration": "Validade: {{expiration}}",
    "container": "Pallet: {{container}}",
    "reasons": "Motivo: {{reasons}}",
    "temperature": "Temperatura (em graus): {{temperature}}",
    "missing-reason": "Motivo: Produto não chegou",
    "confirm-delete": "Tem certeza de que deseja excluir o item selecionado?",
    "delete-error": "Ocorreu um erro ao excluir o item selecionado",
    "received": "Produto recebido, para ver mais acesse Registrados.",
    "received-error": "Ocorreu um erro ao receber o produto",
    "status": {
      "received": "Lotes recebidos",
      "rejected": "Rejeitados",
      "missing": "Faltantes"
    }
  },
  "receipt-scan-location": {
    "title": "Escaneie",
    "scan-label": "Ubicação",
    "scanned": "Pallet movido, para ver mais vá para Movidos.",
    "move-error": "Ocorreu um erro ao mover o pallet"
  },
  "receipt-type": {
    "title": "Tipo de recebimento",
    "transfers": "Transferência",
    "providers": "Fornecedores",
    "to-receive": "O que você vai receber?",
    "help": "Toque no botão dependendo do que você vai receber."
  },
  "receipt-temperature": {
    "title": "Temperatura",
    "temp-label": "Temperatura (em graus)",
    "capture-temp": "Capturar temperatura",
    "out-of-range": "Fora da faixa, aprovada pela qualidade.",
    "update-success": "Temperatura registrada.",
    "update-error": "Ocorreu um erro ao registrar a temperatura",
    "type-error": "Esperava-se uma ordem de compra ao carregar a tela"
  },
  "receipt-container": {
    "title": "Adicionar contêiner",
    "label": "O que você quer fazer com o contêiner?",
    "text-partial": "Se você quiser colocar uma parte do produto no contêiner antigo e outra parte em um novo contêiner, selecione 'Parcial em novo'.",
    "text-full": "Caso queira colocar todo o produto em um novo contêiner, selecione 'Total em novo'.",
    "partial": "Parcial em novo",
    "total": "Total em novo"
  },
  "inventory-transfer": {
    "home": {
      "title": "Transferências de inventário",
      "type-error": "Esperava-se uma matriz de ordens de saída na resposta da receipt-api",
      "skus": "Qtd. SKU: {{total}}"
    },
    "detail": {
      "type-error": "Esperava-se dados de uma ordem da receipt-api",
      "picked-quantity": "Quantidade inserida:",
      "location": "Localização escaneada:",
      "to-pick": "Para Pickear",
      "picked": "Pickeado",
      "upc-sku": "UPC: {{barcode}} | SKU: {{sku}}",
      "requested": "Quantidade solicitada:",
      "not-to-pick": "Não há nada para pickear",
      "not-picked": "Nada pickeado ainda",
      "scan-product": "Escanear produto",
      "already-picked": "O produto escaneado já foi pickeado",
      "not-existing-upc": "O produto escaneado não existe na ordem atual",
      "missing": "Faltante",
      "missing-alert": "Tem certeza de que deseja marcar o produto como faltante?"
    },
    "pick": {
      "quantity": "Quantidade retirada",
      "quantity-gr": "Quantidade retirada em gramas",
      "scan": "Escanear localização",
      "success": "Produto pickeado com sucesso."
    },
    "container": {
      "alert": "Você deve escanear um contêiner, pois há vários contêineres na localização."
    }
  }
}