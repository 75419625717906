import type {
  AddLotEventParams,
  AddMissingParams,
  AddRejectionParams,
  EnterManualUpcEventParams,
  MoveContainerParams,
  ProductEventParams,
  ReceiptEventParams,
  ScanContainerEventParams,
  SetFullMissingParams,
  SetTemperatureEventParams
} from './receipt-analytics.types'
import type { EventProperties } from '@segment/analytics-next'
import type { ProductDetail } from 'types/receipt/model'
import { analytics, getUser } from '../analytics.utils'
import { MEASURE_UNITS } from 'utils/constants'

const EVENT = 'Receipt'
const VERSION = 'v1.0.0'

const errorHandler = (e: any) => {
  console.error('Error calling analytic event', e)
}

const addCommonProps = (props: EventProperties) => {
  const metadata = (window as any)?.WVMetadata
  const warehouse = metadata?.['x-justo-warehouse']?.toUpperCase() || 'PP'
  return { ...props, warehouse, version: VERSION, user: getUser() }
}

const trackEvent = (eventName: string, props: EventProperties) => {
  const data = addCommonProps(props)
  console.info(eventName, data)
  analytics.track(`${EVENT} - ${eventName}`, data).catch(errorHandler)
}

const getProductEventParams = (product: ProductDetail): ProductEventParams => {
  let productMeasureUnit = product?.measureUnit
  if (productMeasureUnit === MEASURE_UNITS.kg) {
    productMeasureUnit = MEASURE_UNITS.gr
  }
  return {
    ean: product?.barcode,
    sku: product?.sku || '',
    productName: product?.description,
    productMeasureUnit,
    expectedQuantity: product?.totalUnits
  }
}

export const syncDocument = (e: ReceiptEventParams) => {
  trackEvent('Initial Synchronization', e)
}

export const registerTemperature = (e: SetTemperatureEventParams) => {
  trackEvent('Temperature Control', e)
}

export const scanContainer = (
  e: ScanContainerEventParams,
  product: ProductDetail
) => {
  trackEvent('Container Scan', {
    ...e,
    ...getProductEventParams(product)
  })
}

export const addLots = (lots: AddLotEventParams[], product: ProductDetail) => {
  lots.forEach((e) => {
    trackEvent('Add Lot', { ...e, ...getProductEventParams(product) })
  })
}

export const addRejection = (e: AddRejectionParams, product: ProductDetail) => {
  trackEvent('Add Rejection', {
    ...e,
    ...getProductEventParams(product)
  })
}

export const addPartialMissing = (
  e: AddMissingParams,
  product: ProductDetail
) => {
  trackEvent('Register Reported Missing', {
    ...e,
    ...getProductEventParams(product)
  })
}

export const setFullMissingStatus = (
  e: SetFullMissingParams,
  product: ProductDetail
) => {
  trackEvent('Set Full Missing Status', {
    ...e,
    ...getProductEventParams(product)
  })
}

export const moveContainer = (e: MoveContainerParams) => {
  trackEvent('Move Container', e)
}

export const finishReceipt = (e: ReceiptEventParams) => {
  trackEvent('Finish Receipt', e)
}

export const enterManualUpc = (e: EnterManualUpcEventParams) => {
  trackEvent('Enter Manual UPC', e)
}
