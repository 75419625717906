import { type MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AtlasBodySubtitle } from '@justomx/atlas-components'
import type { ProductDetail } from 'types/receipt/model'
import { ConfirmationDialogMolecule } from 'components/molecules/ConfirmationDialog.molecule'
import { RadioButton } from 'components/molecules/RadioButton.molecule'
import { RECEIPTS } from 'utils/constants'
import { getCurrentCountry } from 'client/utilities.client-helper'

interface MissingDialogProps {
  dialogRef: MutableRefObject<HTMLDialogElement | null>
  missingCount: number
  product: ProductDetail
  receiptType: string
  coninueAction: () => void
  onMissingReasonChange: (value: string) => void
}

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
`

const labelByMeasureUnit = {
  KG: 'title-kg',
  PIEZA: 'title',
  CAJA: 'title-box'
}

export const MissingDialogOrganism = (props: MissingDialogProps) => {
  const { t } = useTranslation('global')
  const {
    dialogRef,
    missingCount,
    product,
    receiptType,
    coninueAction,
    onMissingReasonChange
  } = props
  const country = getCurrentCountry()

  return (
    <ConfirmationDialogMolecule
      dialogRef={dialogRef}
      confirmText={t('receipt-missing-dialog.continue')}
      onConfirm={coninueAction}
      cancelText={t('receipt-missing-dialog.back')}
    >
      <AtlasBodySubtitle>
        {t(
          `receipt-missing-dialog.${labelByMeasureUnit[product.measureUnit]}`,
          { missing: missingCount.toLocaleString('en-US') }
        )}
      </AtlasBodySubtitle>

      <DialogBody>
        {receiptType === RECEIPTS.providers && (
          <RadioButton
            defaultChecked
            name="reason"
            id="reject"
            value="reject"
            label={t('receipt-missing-dialog.reject')}
            onChange={onMissingReasonChange}
          />
        )}
        {country !== 'BR' && (
          <RadioButton
            defaultChecked={receiptType === RECEIPTS.transfers}
            name="reason"
            id="partialmissing"
            value="partialmissing"
            label={t('receipt-missing-dialog.partial-missing')}
            onChange={onMissingReasonChange}
          />
        )}
      </DialogBody>
    </ConfirmationDialogMolecule>
  )
}
