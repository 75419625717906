import { useEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAtom, useSetAtom } from 'jotai'

import type { ProductDetail } from 'types/receipt/model'
import { Analytics } from 'analytics/analytics.events'
import {
  updateMissings,
  validateContainer
} from 'client/receipt/receipt.client'
import {
  PRODUCT_STATUS,
  RECEIPTS,
  MISSING_TYPES,
  ERRORS
} from 'utils/constants'
import { extractWeight, normalizeEanForCapture } from 'utils/helpers'
import { toastError } from 'utils/toast'
import { useQueryParam, useRequiredParams } from 'hooks/params.hook'
import {
  containerTypeAtom,
  currentOrderAtom,
  scannedContainer,
  selectedProductAtom
} from '../Receipt.state'
import { isAnOrder } from './OrderDetail.loader'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { useGoToScanContainer } from '../scan-container/ScanContainer.navigator'
import { useGoToMoveContainers } from '../move-containers/MoveContainers.navigator'
import { useGoToSummary } from '../summary/Summary.navigator'
import { useGoToReceipt } from '../Receipt.navigator'
import { useGoToRejection } from '../rejection/Rejection.navigator'
import { useGoToProductLots } from '../product-lots/ProductLots.navigator'
import { defaultActiveTab, groupByStatus, tabs } from './OrderDetail.helpers'

export const useOrderDetailData = () => {
  const data = useLoaderData()
  const { orderId, receiptId, receiptType } = useRequiredParams(
    'orderId',
    'receiptId',
    'receiptType'
  )
  const mode = useQueryParam('mode')
  const { t } = useTranslation('global')
  const navigate = useNavigate()

  const [tab, setTab] = useState(defaultActiveTab)
  const [loading, setLoading] = useState(false)
  const [isFullMissing, setIsFullMissing] = useState(false)
  const [order, setOrder] = useAtom(currentOrderAtom)
  const [container, setContainer] = useAtom(scannedContainer)
  const setSelectedProduct = useSetAtom(selectedProductAtom)
  const setContainerType = useSetAtom(containerTypeAtom)
  const [isInitialized, setIsInitialized] = useState(false)
  const scanContainer = useGoToScanContainer()
  const moveContainers = useGoToMoveContainers()
  const summary = useGoToSummary()
  const receipt = useGoToReceipt()
  const rejection = useGoToRejection()
  const productLots = useGoToProductLots()
  const {
    dialogRef: missingsDialogRef,
    open: openMissingDialog,
    value: missingsDialogRefValue
  } = useConfirmationDialog()
  const {
    dialogRef: fullMissingDialogRef,
    open: openFullMissingDialog,
    value: missingDialogRefValue
  } = useConfirmationDialog()
  const { dialogRef: upcDialog, open: openUpcDialog } = useConfirmationDialog()

  useEffect(() => {
    setIsInitialized(true)
  }, [])

  useEffect(() => {
    if (isInitialized || order) {
      if (orderId !== order) {
        setContainer(undefined)
        console.info('setting container to undefined')
      } else {
        console.info('same order, keeping last container in state', container)
        if (container) {
          validateContainer(container)
            .then((validation) => {
              if (
                validation.containerExists &&
                !validation.locationPreStorage
              ) {
                setContainer(undefined)
                return
              }

              console.info('container validation', validation)
              const metadata = (window as any)?.WVMetadata
              const country =
                metadata?.['x-justo-country']?.toUpperCase() || 'MX'
              const validEmptyContainer =
                !validation.containerHasItems || country === 'PE'
              if (!validEmptyContainer) {
                setContainer(undefined)
              }
            })
            .catch((error) => {
              throw new Error(`${error}`)
            })
        }
      }
      setOrder(orderId)
    }
  }, [order, orderId, isInitialized])

  if (!isAnOrder(data)) {
    toastError(t('receipt-order-detail.type-error'))
    throw new Error(t('receipt-order-detail.type-error'))
  }
  const products = groupByStatus(data.products)

  const onSelectTab = (selectedTab: string) => {
    setTab(selectedTab)
  }

  const getTabs = () => {
    return tabs.map((id) => ({
      id,
      label: t(`receipt-order-detail.${id}`, {
        counter: products[id]?.length || 0
      })
    }))
  }

  const validateProduct = (
    barcode: string,
    showNotFoundAlert = true
  ): { product?: ProductDetail, error: string } => {
    const product = data.products.find((product) => product.barcode === barcode)
    if (!product) {
      if (showNotFoundAlert) {
        toastError(t('receipt-order-detail.nonexistent-upc', { upc: barcode }))
      }
      return { error: ERRORS.upcNotFound }
    } else if (product.status !== PRODUCT_STATUS.pending) {
      toastError(t('receipt-order-detail.registered-upc', { upc: barcode }))
      return { error: ERRORS.alreadyReceived }
    }
    return { error: '', product }
  }

  const scanProduct = (upc: string) => {
    const result = validateProduct(upc, false)
    let scannedProduct = result.product
    if (result.error === ERRORS.upcNotFound) {
      const barcode = normalizeEanForCapture(upc)
      const grams = extractWeight(upc)
      console.info('grams', grams)

      const resultWeighable = validateProduct(barcode)
      if (resultWeighable.error.length) {
        return
      }
      scannedProduct = resultWeighable.product
    } else if (result.error.length) return

    setContainerType('')
    setSelectedProduct(scannedProduct)
    if (container && scannedProduct) {
      productLots.go(receiptId, orderId, scannedProduct.productId, false)
    } else {
      scanContainer.go(receiptId, orderId)
    }
  }

  const onSelectProduct = (product: ProductDetail) => {
    setIsFullMissing(!!product.isFullMissing)
    if (product.status === PRODUCT_STATUS.pending) {
      if (receiptType === RECEIPTS.transfers) {
        openFullMissingDialog(product)
      } else {
        openMissingDialog(product)
      }
    } else {
      if (product.isFullMissing) {
        openFullMissingDialog(product)
      } else {
        setSelectedProduct(product)
        summary.go(receiptId, orderId, product.productId)
      }
    }
  }

  const onSelectMissingType = async (type: string) => {
    const selectedProduct = missingsDialogRefValue as ProductDetail
    if (type === MISSING_TYPES.fullMissing) {
      await onConfirmFullMissing(selectedProduct)
    } else {
      setSelectedProduct(selectedProduct)
      rejection.go(receiptId, orderId, selectedProduct.productId)
    }
  }

  const goToContainersPage = () => {
    moveContainers.go(receiptId, orderId)
  }

  const onConfirmFullMissing = async (product: ProductDetail) => {
    try {
      setLoading(true)
      const {
        productId: id,
        barcode,
        totalUnits: expectedQuantity,
        isFullMissing = false
      } = product
      const newIsFullMissing = !isFullMissing
      await updateMissings({
        product: { id, barcode, expectedQuantity },
        receiptId,
        isFullMissing: newIsFullMissing
      })
      Analytics.receipt.setFullMissingStatus({ orderId, receiptType }, product)

      if (newIsFullMissing && products.pending?.length === 1) {
        goToContainersPage()
      } else {
        navigate(0)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onSelectKeyboard = () => {
    openUpcDialog()
  }

  const onConfirmManualUpc = (upc: string) => {
    if (!upc?.length) return
    Analytics.receipt.enterManualUpc({
      orderId,
      receiptType,
      ean: upc
    })
    scanProduct(upc.trim())
  }

  return {
    orderDetail: data,
    orderId,
    products,
    tab,
    missingsDialogRef,
    fullMissingDialogRef,
    loading,
    isFullMissing,
    missingDialogRefValue,
    mode,
    upcDialog,
    actions: {
      scanProduct,
      onSelectTab,
      getTabs,
      onSelectProduct,
      onConfirmFullMissing,
      onSelectMissingType,
      goToContainersPage,
      onSelectKeyboard,
      onConfirmManualUpc,
      goToReceipt: () => {
        receipt.go()
      }
    }
  }
}
